<template>
    <div>
        <div class="section mt-2 text-center">
            <img class="auth-logo" src="../../assets/img/metriclogo_color.png" alt="metrics logo" style="">
            <h3 class="mt-3">Reset Password</h3>
            <!--            <h4>Fill the form to log in</h4>-->
        </div>
        <div class="section mb-5 p-2">

            <form role="form" @submit.prevent="sendLink">
                <div class="card">
                    <div class="card-body pb-1">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="email1">E-mail</label>
                                <input type="email" v-model="email" class="form-control" id="email1" placeholder="Your e-mail">
                            </div>
                        </div>
                    </div>
                </div>


                <div class="form-links mt-2">
                    <div>
                        <router-link :to="{name: 'Auth'}">
                            <ion-icon name="chevron-back-outline"></ion-icon> Login
                        </router-link>
                    </div>
                </div>

                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Send Reset Link</button>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import methodsMixin from '../../utils/methodsMixin';

export default {
    name: 'resetPassword',
    data(){
        return {
            email: ''
        }
    },
    mixins: [methodsMixin],
    methods: {
        async sendLink(){
            // return
            if(!this.validateEmail(this.email)){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Invalid email address!',
                    timeout: 1500
                })
            }
            this.$loader.show();
            let response = await this.$store.dispatch('auth/resetPassword', this.email);
            this.$loader.hide();
            if(response.status){
                console.log(response.data);
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Password reset link sent!',
                })
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: response.message,
                })
            }

        }
    }
};
</script>

<style scoped>
.auth-logo{
    width: 100px;
    height: 100px
}
</style>